%necessities-button {
  @apply
  transition
  duration-150
  hover:brightness-90
  disabled:cursor-no-drop
  disabled:bg-brand-gray-200
  disabled:text-brand-gray-400
  select-none
  whitespace-nowrap
  gap-3
  font-bold
  overflow-hidden;
}

// Primary Button

.primary-button {
  @extend %necessities-button;
  @apply flex bg-brand-lilac-600 rounded-full items-center justify-center hover:enabled:bg-[#2f3175] enabled:text-white;
}

.primary-button i {
  @apply bg-white #{!important}
}

.primary-button:disabled > i {
  @apply bg-brand-gray-400 #{!important}
}

// Secondary Button

.secondary-button {
  @extend %necessities-button;
	@apply flex bg-brand-dark rounded-full items-center justify-center hover:bg-brand-dark-gray text-white;
}

.secondary-button i {
  @apply bg-white #{!important}
}

.secondary-button:disabled > i {
  @apply bg-brand-gray-400 #{!important}
}

// Tertiary Button

.tertiary-button {
  @extend %necessities-button;
  @apply flex items-center justify-center bg-white shadow rounded-full hover:bg-brand-gray-50 aspect-square;
}

// Option Button

.option-button {
  @extend %necessities-button;
	@apply flex items-center justify-center bg-brand-gray-200 rounded-full text-brand-gray-600;
}

.option-button i {
  @apply bg-brand-gray-600 #{!important}
}

// Option Button White

.option-button-white {
  @extend %necessities-button;
  @apply flex items-center justify-center bg-white rounded-full text-brand-gray-600;
}

// Delete Button

.delete-button {
  @extend %necessities-button;
	@apply flex bg-[#FFE8E8] rounded-full items-center justify-center hover:filter text-brand-error-800;
}

.delete-button i {
  @apply bg-brand-error-800 #{!important}
}

// Sizes

.small-btn {
  @apply h-8 px-4 text-sm #{!important};
}

.medium-btn {
  @apply h-12 px-6 text-sm #{!important};
}

.large-btn {
  @apply h-16 px-6 text-base #{!important};
}
