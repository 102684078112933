@font-face {
	font-family: Inter;
	src: url('../assets/fonts/inter/Inter-Regular.woff2') format('woff2'),
	url('../assets/fonts/inter/Inter-Regular.woff') format('woff');
	font-weight: normal;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: Inter;
	src: url('../assets/fonts/inter/Inter-Medium.woff2') format('woff2'),
	url('../assets/fonts/inter/Inter-Medium.woff') format('woff');
	font-weight: 500;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: Inter;
	src: url('../assets/fonts/inter/Inter-Thin.woff2') format('woff2'),
	url('../assets/fonts/inter/Inter-Thin.woff') format('woff');
	font-weight: 100;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: Inter;
	src: url('../assets/fonts/inter/Inter-Bold.woff2') format('woff2'),
	url('../assets/fonts/inter/Inter-Bold.woff') format('woff');
	font-weight: bold;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: Inter;
	src: url('../assets/fonts/inter/Inter-SemiBold.woff2') format('woff2'),
	url('../assets/fonts/inter/Inter-SemiBold.woff') format('woff');
	font-weight: 600;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: Inter;
	src: url('../assets/fonts/inter/Inter-Black.woff2') format('woff2'),
	url('../assets/fonts/inter/Inter-Black.woff') format('woff');
	font-weight: 900;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: Inter;
	src: url('../assets/fonts/inter/Inter-Light.woff2') format('woff2'),
	url('../assets/fonts/inter/Inter-Light.woff') format('woff');
	font-weight: 300;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: Inter;
	src: url('../assets/fonts/inter/Inter-ExtraLight.woff2') format('woff2'),
	url('../assets/fonts/inter/Inter-ExtraLight.woff') format('woff');
	font-weight: 200;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: Inter;
	src: url('../assets/fonts/inter/Inter-ExtraBold.woff2') format('woff2'),
	url('../assets/fonts/inter/Inter-ExtraBold.woff') format('woff');
	font-weight: bold;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'Whyte';
	src: url('../assets/fonts/whyte/Whyte-BoldItalic.woff2') format('woff2'),
	url('../assets/fonts/whyte/Whyte-BoldItalic.woff') format('woff');
	font-weight: bold;
	font-style: italic;
	font-display: swap;
}

@font-face {
	font-family: 'Whyte';
	src: url('../assets/fonts/whyte/Whyte-HeavyItalic.woff2') format('woff2'),
	url('../assets/fonts/whyte/Whyte-HeavyItalic.woff') format('woff');
	font-weight: 900;
	font-style: italic;
	font-display: swap;
}

@font-face {
	font-family: 'Whyte';
	src: url('../assets/fonts/whyte/Whyte-Heavy.woff2') format('woff2'),
	url('../assets/fonts/whyte/Whyte-Heavy.woff') format('woff');
	font-weight: 900;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'Whyte';
	src: url('../assets/fonts/whyte/Whyte-ExtraLight.woff2') format('woff2'),
	url('../assets/fonts/whyte/Whyte-ExtraLight.woff') format('woff');
	font-weight: 200;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'Whyte';
	src: url('../assets/fonts/whyte/Whyte-ExtraLightItalic.woff2') format('woff2'),
	url('../assets/fonts/whyte/Whyte-ExtraLightItalic.woff') format('woff');
	font-weight: 200;
	font-style: italic;
	font-display: swap;
}

@font-face {
	font-family: 'Whyte';
	src: url('../assets/fonts/whyte/Whyte-Italic.woff2') format('woff2'),
	url('../assets/fonts/whyte/Whyte-Italic.woff') format('woff');
	font-weight: normal;
	font-style: italic;
	font-display: swap;
}

@font-face {
	font-family: 'Whyte';
	src: url('../assets/fonts/whyte/Whyte-Black.woff2') format('woff2'),
	url('../assets/fonts/whyte/Whyte-Black.woff') format('woff');
	font-weight: 900;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'Whyte';
	src: url('../assets/fonts/whyte/Whyte-Bold.woff2') format('woff2'),
	url('../assets/fonts/whyte/Whyte-Bold.woff') format('woff');
	font-weight: bold;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'Whyte Book';
	src: url('../assets/fonts/whyte/Whyte-BookItalic.woff2') format('woff2'),
	url('../assets/fonts/whyte/Whyte-BookItalic.woff') format('woff');
	font-weight: normal;
	font-style: italic;
	font-display: swap;
}

@font-face {
	font-family: 'Whyte Book';
	src: url('../assets/fonts/whyte/Whyte-Book.woff2') format('woff2'),
	url('../assets/fonts/whyte/Whyte-Book.woff') format('woff');
	font-weight: normal;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'Whyte';
	src: url('../assets/fonts/whyte/Whyte-BlackItalic.woff2') format('woff2'),
	url('../assets/fonts/whyte/Whyte-BlackItalic.woff') format('woff');
	font-weight: 900;
	font-style: italic;
	font-display: swap;
}

@font-face {
	font-family: 'Whyte';
	src: url('../assets/fonts/whyte/Whyte-LightItalic.woff2') format('woff2'),
	url('../assets/fonts/whyte/Whyte-LightItalic.woff') format('woff');
	font-weight: 300;
	font-style: italic;
	font-display: swap;
}

@font-face {
	font-family: 'Whyte';
	src: url('../assets/fonts/whyte/Whyte-Light.woff2') format('woff2'),
	url('../assets/fonts/whyte/Whyte-Light.woff') format('woff');
	font-weight: 300;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'Whyte';
	src: url('../assets/fonts/whyte/Whyte-Thin.woff2') format('woff2'),
	url('../assets/fonts/whyte/Whyte-Thin.woff') format('woff');
	font-weight: 100;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'Whyte Super';
	src: url('../assets/fonts/whyte/Whyte-Super.woff2') format('woff2'),
	url('../assets/fonts/whyte/Whyte-Super.woff') format('woff');
	font-weight: 900;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'Whyte';
	src: url('../assets/fonts/whyte/Whyte-Medium.woff2') format('woff2'),
	url('../assets/fonts/whyte/Whyte-Medium.woff') format('woff');
	font-weight: 500;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'Whyte';
	src: url('../assets/fonts/whyte/Whyte-ThinItalic.woff2') format('woff2'),
	url('../assets/fonts/whyte/Whyte-ThinItalic.woff') format('woff');
	font-weight: 100;
	font-style: italic;
	font-display: swap;
}

@font-face {
	font-family: 'Whyte Super';
	src: url('../assets/fonts/whyte/Whyte-SuperItalic.woff2') format('woff2'),
	url('../assets/fonts/whyte/Whyte-SuperItalic.woff') format('woff');
	font-weight: 900;
	font-style: italic;
	font-display: swap;
}

@font-face {
	font-family: 'Whyte';
	src: url('../assets/fonts/whyte/Whyte-Regular.woff2') format('woff2'),
	url('../assets/fonts/whyte/Whyte-Regular.woff') format('woff');
	font-weight: normal;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'Whyte';
	src: url('../assets/fonts/whyte/Whyte-MediumItalic.woff2') format('woff2'),
	url('../assets/fonts/whyte/Whyte-MediumItalic.woff') format('woff');
	font-weight: 500;
	font-style: italic;
	font-display: swap;
}

@font-face {
  font-family: 'DM Sans';
  src: url('../assets/fonts/dm/DMSans-MediumItalic.woff2') format('woff2'),
  url('../assets/fonts/dm/DMSans-MediumItalic.woff') format('woff');
  font-weight: 500;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'DM Sans';
  src: url('../assets/fonts/dm/DMSans-Italic.woff2') format('woff2'),
  url('../assets/fonts/dm/DMSans-Italic.woff') format('woff');
  font-weight: normal;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'DM Sans';
  src: url('../assets/fonts/dm/DMSans-BoldItalic.woff2') format('woff2'),
  url('../assets/fonts/dm/DMSans-BoldItalic.woff') format('woff');
  font-weight: bold;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'DM Sans';
  src: url('../assets/fonts/dm/DMSans-Bold.woff2') format('woff2'),
  url('../assets/fonts/dm/DMSans-Bold.woff') format('woff');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'DM Sans';
  src: url('../assets/fonts/dm/DMSans-Regular.woff2') format('woff2'),
  url('../assets/fonts/dm/DMSans-Regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'DM Sans';
  src: url('../assets/fonts/dm/DMSans-Medium.woff2') format('woff2'),
  url('../assets/fonts/dm/DMSans-Medium.woff') format('woff');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@layer base {
	html {
		font-family: "DM Sans", serif !important;
	}

  body {
    font-family: "DM Sans", serif !important;
  }

  input {
    font-family: "DM Sans", serif !important;
    font-size: 16px;
    font-weight: 500;
  }
}
