.svg-fill-primary {
	filter: brightness(0) saturate(100%) invert(3%) sepia(5%) saturate(2468%) hue-rotate(169deg) brightness(93%) contrast(96%);
}

.svg-fill-lilac-400 {
  filter: invert(55%) sepia(12%) saturate(2313%) hue-rotate(200deg) brightness(95%) contrast(76%);
}

.svg-fill-white {
	filter: invert(100%) sepia(59%) saturate(0%) hue-rotate(146deg) brightness(116%) contrast(100%);
}

.svg-fill-gray-400 {
  filter: brightness(0) saturate(100%) invert(43%) sepia(7%) saturate(700%) hue-rotate(178deg) brightness(98%) contrast(82%);
}

.svg-fill-error-800 {
	filter: brightness(0) saturate(100%) invert(13%) sepia(65%) saturate(2577%) hue-rotate(342deg) brightness(103%) contrast(95%);
}

.svg-fill-iceberg-600 {
  filter: invert(31%) sepia(9%) saturate(2676%) hue-rotate(174deg) brightness(92%) contrast(86%);
}

.svg-fill-black {
  filter: invert(0%) sepia(4%) saturate(0%) hue-rotate(324deg) brightness(102%) contrast(105%);
}
